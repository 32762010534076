export const COLLECTION_MAIN_TAG_PREFIX = "collection_main_"
export const COLLECTION_TAG_PREFIX = "collection_"
const DEFAULT_HANDLE = "product"

export const handleFromTags = (tags?: string[]) => {
  return (
    tags
      ?.find((tag) => tag.startsWith(COLLECTION_MAIN_TAG_PREFIX))
      ?.replace(COLLECTION_MAIN_TAG_PREFIX, "") ?? DEFAULT_HANDLE
  )
}
